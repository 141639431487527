.progressBar {
  background: var(--progress-bar-color, var(--color-gray-000));
  height: 2px;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
