.navbar {
  background: var(--color-gray-000);
  bottom: 0;
  box-shadow: 0 0 10px 10px rgb(0 0 0 / 8%);
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: var(--layout-navbar-z-index);
}

.navbarItems {
  align-items: baseline;
  display: flex;
  font: var(--typography-label-s);
  font-size: 12px;
  justify-content: center;
  margin-left: var(--spacing-05);
  margin-right: var(--spacing-05);
}

.navbarItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 64px;
  padding: var(--spacing-02) var(--spacing-02);
  white-space: nowrap;
}

.navbarItem svg {
  fill: none;
  height: 24px;
  margin-bottom: var(--spacing-01);
  overflow: visible;
  stroke: var(--color-gray-900);
  stroke-width: 1.5px;
  width: 24px;
}

.navbarItemSelected {
  color: var(--nav-bar-color-selected, var(--color-main-500));
  font-weight: bold;
}

.navbarItemSelected svg {
  stroke: var(--nav-bar-color-selected, var(--color-main-500));
  stroke-width: 2.5px;
}

@media (--media-l) {
  .navbar {
    display: none;
  }
}
