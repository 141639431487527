.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layoutFocusMode {
  /* Disable lint to avoid calc problems */
  /* stylelint-disable-next-line length-zero-no-unit */
  --layout-navbar-height: 0px;
}

.header {
  background: var(
    --header-background-color,
    var(--theme-contrast-background-color)
  );
  color: var(
    --header-color,
    var(--theme-contrast-color, var(--color-gray-000))
  );
  fill: currentcolor;
  height: var(--layout-header-height);
  position: sticky;
  stroke: currentcolor;
  top: 0;
  width: 100%;
  z-index: 8;
}

.headerWrap {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-03);
  height: 100%;
  justify-content: space-between;
  margin: auto;
  max-width: var(--grid-width);
  padding: 0 var(--spacing-05);
  width: 100%;
}

.headerLogo {
  height: 32px;
}

.headerLogoLink {
  display: inline-block;
}

.headerLogo svg {
  fill: inherit;
  height: 32px;
  stroke: none;
  width: auto;
}

.searchBar {
  order: 10;
}

.headerMobileNav {
  align-items: center;
  display: flex;
  font: var(--typography-label-m);
  min-height: 26px;
  min-width: 0;
}

.headerMobileBack {
  border-radius: 50%;
  height: 26px;
  padding: 5px;
  stroke-width: 1.5px;
  transform: translate(-8px, -1px);
  width: 26px;
}

.headerMobileBackButton {
  height: 100%;
  width: 100%;
}

.headerMobileNavTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerActions {
  align-items: center;
  display: flex;
  gap: var(--spacing-03);
  justify-content: flex-end;
}

.headerAction {
  align-items: center;
  cursor: pointer;
  display: none;
  font: var(--typography-label-m);
}

.headerAction svg {
  fill: none;
  height: 24px;
  margin-left: var(--spacing-02);
  margin-right: var(--spacing-02);
  stroke-width: 1.5px;
  width: 24px;
}

.headerActionContact span,
.headerActionLogout span {
  display: none;
}

.headerLanguageSelector {
  --button-background-color: transparent;
  --button-background-color-hover: transparent;
  --button-color: var(
    --header-color,
    var(--theme-contrast-color, var(--color-gray-000))
  );
  --button-color-hover: var(
    --header-color,
    var(--theme-contrast-color, var(--color-gray-000))
  );
  --button-padding: 0;
}

.layoutFocusMode .headerActionContact {
  display: flex;
}

.layout:not(.layoutRootPage) .headerLogo {
  display: none;
}

.layoutRootPage .headerMobileNav {
  display: none;
}

.layoutHeaderTransparent .header {
  position: fixed;
}

.layoutIsScrolled .header {
  box-shadow: 1px 3px 4px rgb(0 0 0 / 5%);
}

.layoutHeaderTransparent:not(.layoutIsScrolled) .header {
  background: transparent;
  color: var(--header-color-not-scrolled, var(--theme-contrast-color));
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.preFooter,
.footer {
  background: var(--color-gray-900);
  color: var(--color-gray-000);
  fill: var(--color-gray-000);
}

.preFooter {
  border-bottom: 1px solid var(--color-gray-800);
  font: var(--typography-paragraph-xs);
}

.preFooterContent:not(:empty) {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03) var(--spacing-05);
  justify-content: space-between;
  padding: var(--spacing-02) 0;
}

.preFooterBreadcrumbs {
  display: flex;
  gap: var(--spacing-02);
}

.preFooterBreadcrumbs > li:not(:last-child)::after {
  content: "/";
  margin-left: var(--spacing-02);
}

.footer {
  font: var(--typography-paragraph-s);
  padding: var(--spacing-08) 0;
}

.footerLogo > svg {
  height: 36px;
  width: auto;
}

.footerSocial,
.footerLanguageSelector {
  display: flex;
}

.footerSocial > * {
  fill: none;
  margin-right: var(--spacing-03);
  margin-top: var(--spacing-03);
  stroke: var(--color-gray-000);
  stroke-width: 1px;
  width: 32px;
}

.footerLanguageSelector {
  --button-background-color: var(--color-gray-700);

  margin-top: var(--spacing-03);
}

.footerLanguageSelector a {
  display: inline-block;
  padding: var(--spacing-03);
}

.footerNavGroup,
.footerNavGroupLeft {
  margin-top: var(--spacing-07);
}

.footerNavHeader {
  font: var(--typography-label-m);
}

.footerNavLinks {
  margin-top: var(--spacing-03);
}

.footerNavLinks a {
  display: block;
  padding-bottom: var(--spacing-02);
  padding-top: var(--spacing-02);
}

.footerSlogan {
  font: var(--typography-paragraph-m);
  margin-top: var(--spacing-02);
}

.footerBottom {
  color: var(--color-gray-500);
  grid-row-gap: var(--spacing-01);
  margin-top: var(--spacing-08);
}

.footerAppBadges {
  display: flex;
  gap: var(--spacing-04);
  margin-top: var(--spacing-03);
}

.footerAppBadges > * {
  flex-grow: 1;
  max-width: 170px;
}

@media (hover: hover) and (pointer: fine) {
  .footerNavLinks a {
    padding-top: var(--spacing-01);
  }
}

@media (--media-m) {
  .header {
    position: initial;
  }

  .layoutWithSearchBar .header {
    position: fixed;
  }

  .layoutWithSearchBar .headerWrap {
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
  }

  .layoutIsScrolled:not(.layoutHeaderTransparent) .header {
    box-shadow: none;
  }

  .layout:not(.layoutRootPage) .headerLogo {
    display: block;
  }

  .searchBar {
    order: 0;
  }

  .headerMobileNav {
    display: none;
  }

  .headerActions {
    gap: var(--spacing-05);
  }

  .headerActionContact span,
  .headerActionLogout span {
    display: block;
  }

  .preFooterContent:not(:empty) {
    flex-direction: row;
  }

  .preFooterCovid:only-child {
    margin-left: auto;
  }

  .footerBrand {
    --grid-item-columns: 4;
  }

  .footerNav {
    --grid-item-columns: 8;
  }

  .footerNavList {
    display: grid;
    grid-gap: var(--spacing-05) var(--grid-gutter);
    grid-template-columns: repeat(var(--grid-item-columns), 1fr);
  }

  .footerNavGroup {
    grid-column: span 4;
    margin-top: 0;
  }

  .footerNavGroup:first-child {
    grid-row: 1/3;
  }

  .footerBottom {
    text-align: center;
  }
}

@media (--media-l) {
  .headerAction {
    display: flex;
  }
}
