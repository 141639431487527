@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1s infinite linear;
  height: 50px;
}
