.onlineReception {
  --action-sheet-content-margin: 0;
}

.onlineReception,
.overlay {
  z-index: 11;
}

.goBack {
  text-align: center;
}

.description + .contactOptions {
  margin-top: var(--spacing-02);
}

.children {
  margin-top: var(--spacing-05);
}
