.listItem + .listItem {
  border-top: 1px solid var(--list-item-border-color, var(--color-gray-200));
}

.listItem > * {
  text-align: left;
  width: 100%;
}

.listItemWrap {
  display: flex;
  gap: var(--spacing-04);
  padding: var(--list-item-padding, var(--spacing-04) 0);
}

.listItemNoChildren .listItemWrap {
  align-items: center;
}

.listItemWrap > * {
  flex-shrink: 0;
}

.listItemWrap > .listItemContent {
  flex-grow: 1;
  flex-shrink: 1;
}

.listItemTitle {
  font: var(--typography-label-m);
}

.listItemChildren {
  font: var(--typography-paragraph-s);
}

.listItemError {
  background: var(--color-error-050);
  color: var(--color-error-500);
}

.listItemInfo {
  background: var(--color-info-050);
  color: var(--color-info-500);
}

.listItemSuccess {
  background: var(--color-success-050);
  color: var(--color-success-500);
}

.listItemWarn {
  background: var(--color-warn-050);
  color: var(--color-warn-500);
}
