:root {
  /* Spacing */
  --spacing-01: 4px;
  --spacing-02: 8px;
  --spacing-03: 12px;
  --spacing-04: 16px;
  --spacing-05: 20px;
  --spacing-06: 24px;
  --spacing-07: 32px;
  --spacing-08: 48px;
  --spacing-09: 64px;
  --spacing-10: 72px;

  /* Grid width */
  --grid-width: 100%;
  --grid-gutter: 16px;
  --grid-item-columns: 12;

  /* Layout */
  --layout-header-height: 64px;
  --layout-navbar-height: 64px;
  --layout-navbar-shadow: 0 -10px 10px 0 rgb(0 0 0 / 3%);
  --layout-navbar-z-index: 5;
}

@media (--media-m) {
  :root {
    --grid-gutter: 32px;
  }
}

@media (--media-l) {
  :root {
    --grid-width: 960px;
    --grid-gutter: 56px;
  }
}

@media (--media-xl) {
  :root {
    --grid-width: 1096px;
  }
}

@media (--media-2xl) {
  :root {
    --grid-width: 1288px;
  }
}

html {
  scroll-padding: var(--layout-header-height) 0 100px; /* Avoids hidding content behind sticky header/footer on tab navigation */
}

body {
  color: var(--color-gray-900);
  font: var(--typography-paragraph-m);
}

.grid {
  display: grid;
  grid-column-gap: var(--grid-gutter);
  grid-template-columns: repeat(12, 1fr);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--spacing-05);
  padding-right: var(--spacing-05);
  width: var(--grid-width);
}

.grid > * {
  grid-column: span var(--grid-item-columns);
}

.grid .grid {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.isKiosk {
  zoom: 125%;
}

.isKiosk .grid {
  width: 100%;
}

@media (--media-l) {
  :root {
    --layout-navbar-height: 0;
  }
}
