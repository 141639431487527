.icon,
.icon svg {
  height: 24px;
  width: 24px;
}

.icon svg {
  fill: none;
  stroke: var(--list-item-icon-color, currentcolor);
  stroke-width: 1.5px;
}
